import { H3, H4, Paragraph } from '@thisisdevelopment/akzonobel-core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Link from 'next/link';
import theme from 'theme';
import {
  CmsSectionStyled,
  Content,
  Image,
  ImageAlign,
  SectionStyle,
} from './styled';
import { useEffect, useState } from 'react';

export type CmsSectionType = {
  title: string;
  sub_title?: string;
  text: string;
  button_link: string;
  button_label: string;
  file_id: number;
  image_align: ImageAlign;
  style: SectionStyle;
  spacing: boolean;
};

type PropType = {
  section: CmsSectionType;
  sectionKey: string;
};

function CmsSection({ section, sectionKey }: PropType) {
  const isDesktop = useMediaQuery(theme.mediaQuery.desktop);
  const title = section.title ?? '';
  const sub_title = section.sub_title ?? '';
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? (
    <CmsSectionStyled
      imageAlign={section.image_align}
      sectionStyle={section.style}
      hasSpacing={section.spacing}
    >
      <Content>
        {section.style === 'call2action' && isDesktop ? (
          <>
            <H3>{title.split('|')[0]}</H3>
            <H4>{title.split('|')[1]}</H4>
          </>
        ) : (
          <H3>{title.replace('|', ' ')}</H3>
        )}
        {sub_title && <H4>{sub_title}</H4>}
        {section.text &&
          section.text
            .split('\n')
            .map((paragraph, index) => (
              <Paragraph key={`${sectionKey}_Paragraph_${index}`}>
                {paragraph}
              </Paragraph>
            ))}
        {section.button_link && (
          <Link href={section.button_link} legacyBehavior>
            {section.button_label}
          </Link>
        )}
      </Content>
      {section.file_id && (
        <Link href={section.button_link ?? '#'}>
          <Image
            id={section.file_id}
            alt="Section Image"
            priority={sectionKey === 'Section_0'}
          />
        </Link>
      )}
    </CmsSectionStyled>
  ) : null;
}

export default CmsSection;
